.legacy-etl-middle-panel-title {
    text-align: center;
    margin-bottom: 0px !important;
}

.legacy-etl-middle-panel-container {
    height: calc(100vh - 220px);
    width: 100%;
    box-sizing: border-box;
}

.MuiButtonBase-root.MuiTab-root:hover {
    background-color: transparent !important;
    background: none !important;
    opacity: 1;
}

.sequences-table-container {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-right: 8px;
    padding-top: 8px;
}

.search-container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 12px;
    gap: 8px;
    align-items: center;
}

.sequences-search {
    width: 300px;
}

.sequences-search .MuiOutlinedInput-notchedOutline {
    border-color: #e0e0e0;
}

.sequences-search .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #1976d2;
    border-width: 1px;
}

.sequences-search .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #bdbdbd;
}

.sequences-search .MuiInputBase-input:focus {
    box-shadow: none;
}

.sequences-search-clear {
    color: rgba(0, 0, 0, 0.54) !important;
    padding: 4px !important;
    margin-right: -8px !important;
}

.sequences-search-clear:hover {
    color: rgba(0, 0, 0, 0.87) !important;
}

.sequences-grid-container {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.sequences-grid-container .MuiDataGrid-root {
    border-radius: 10px;
    background-color: #fff;
}

.sequences-grid-container .MuiDataGrid-cell {
    border-bottom: 1px solid #f0f0f0;
    padding: 8px 16px;
    font-size: 0.875rem;
    color: #333;
}

.sequences-grid-container .MuiDataGrid-row {
    min-height: 30px !important;
    max-height: 30px !important;
}

.sequences-grid-container .MuiDataGrid-row:hover {
    background-color: rgba(37, 150, 209, 0.04);
}

.sequences-grid-container .MuiDataGrid-row.Mui-selected {
    color: #2596D1;
    background-color: rgba(37, 150, 209, 0.08);
}

.sequences-grid-container .MuiDataGrid-cell:focus,
.sequences-grid-container .MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.sequences-grid-container .MuiDataGrid-columnHeaders {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    min-height: 40px !important;
    max-height: 40px !important;
}

.sequences-grid-container .MuiDataGrid-columnHeader {
    height: 40px !important;
    line-height: 40px !important;
    outline: none !important;
}

.sequences-grid-container .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0 !important;
}

.sequences-grid-container .MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
    color: #333;
    font-size: 0.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sequences-grid-container .MuiDataGrid-columnHeaderContent {
    padding: 0 !important;
}

.sequences-grid-container .MuiDataGrid-virtualScroller {
    margin-top: 0 !important;
}

.sequences-grid-container .MuiDataGrid-footerContainer {
    display: none;
}

.sequences-grid-container .MuiDataGrid-columnSeparator {
    display: none;
}

.sequences-grid-container .MuiDataGrid-columnHeader:focus,
.sequences-grid-container .MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rotating-icon {
    animation: rotate 2s linear infinite;
}

.legacy-etl-container {
  padding: 16px 24px 24px;
  height: calc(100vh - 100px);
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.legacy-etl-title {
  font-size: 24px !important;
  font-weight: bold !important;
  margin-bottom: 16px !important;
}

.MuiButtonBase-root.MuiTab-root:hover {
  background-color: transparent !important;
  background: none !important;
  opacity: 1;
}

.users-container {
  padding: 16px 24px 24px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.users-title {
  font-size: 24px !important;
  font-weight: bold !important;
  margin-bottom: 16px !important;
}

.search-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 12px;
  gap: 8px;
  align-items: center;
}

.users-search {
  width: 300px;
}

.users-search .MuiOutlinedInput-notchedOutline {
  border-color: #e0e0e0;
}

.users-search .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #1976d2;
  border-width: 1px;
}

.users-search .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #bdbdbd;
}

.users-search .MuiInputBase-input:focus {
  box-shadow: none;
}

.users-search-clear {
  color: rgba(0, 0, 0, 0.54) !important;
  padding: 4px !important;
  margin-right: -8px !important;
}

.users-search-clear:hover {
  color: rgba(0, 0, 0, 0.87) !important;
}

.users-refresh-button {
  color: #1976d2 !important;
  padding: 8px !important;
}

.users-refresh-button:hover {
  background-color: rgba(25, 118, 210, 0.04) !important;
}

.users-grid-container {
  height: 600px;
  width: 100%;
  overflow-x: auto;
}

.users-grid-container .MuiDataGrid-root {
  border: 1px solid #e0e0e0;
  background-color: #fff;
  border-radius: 4px;
}

.users-grid-container .MuiDataGrid-cell {
  border-bottom: 1px solid #f0f0f0;
  padding: 8px 16px;
  font-size: 0.875rem;
  color: #333;
}

.users-grid-container .MuiDataGrid-row {
  min-height: 36px !important;
  max-height: 36px !important;
}

.users-grid-container .MuiDataGrid-row:nth-of-type(odd) {
  background-color: #f8fafb;
}

.users-grid-container .MuiDataGrid-row:hover {
  background-color: rgba(25, 118, 210, 0.04);
}

.users-grid-container .MuiDataGrid-cell:focus {
  outline: none;
}

.users-grid-container .MuiDataGrid-columnHeaders {
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  min-height: 28px !important;
  max-height: 28px !important;
  line-height: 28px !important;
}

.users-grid-container .MuiDataGrid-columnHeader {
  padding: 0 8px !important;
  height: 28px !important;
  outline: none !important;
}

.users-grid-container .MuiDataGrid-columnHeaderTitleContainer {
  padding: 0 !important;
}

.users-grid-container .MuiDataGrid-columnHeaderTitle {
  font-weight: 600;
  color: #333;
  font-size: 0.875rem;
  line-height: 28px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 2px !important;
}

.users-grid-container .MuiDataGrid-columnHeaderContent {
  padding: 0 !important;
}

.users-grid-container .MuiDataGrid-virtualScroller {
  margin-top: 10px !important;
}

.users-grid-container .MuiDataGrid-footerContainer {
  display: none;
}

.users-grid-container .MuiDataGrid-cell--textLeft {
  padding-left: 16px;
}

.users-grid-container .MuiDataGrid-columnSeparator {
  display: none;
}

.users-grid-container .MuiDataGrid-columnHeader--sorted {
  background-color: transparent;
}

.users-grid-container .MuiDataGrid-columnHeader--moving {
  background-color: transparent;
}

.users-grid-container .MuiDataGrid-iconButtonContainer {
  width: 20px !important;
  height: 20px !important;
  margin-left: 0 !important;
  margin-right: -4px !important;
}

.users-grid-container .MuiDataGrid-menuIcon {
  width: 20px !important;
  height: 20px !important;
}

.users-grid-container .MuiDataGrid-sortIcon {
  width: 16px !important;
  height: 16px !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.users-grid-container .MuiDataGrid-columnHeader:hover .MuiDataGrid-sortIcon {
  color: rgba(0, 0, 0, 0.87) !important;
}

.checkmark-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.date-tooltip {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  font-size: 0.875rem;
}

.group-switch-cell {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;
  width: 100%;
}

.copy-button {
  color: #1976d2 !important;
  padding: 4px !important;
}

.copy-button:hover {
  background-color: rgba(25, 118, 210, 0.04) !important;
}

.copy-button.copied {
  color: #4caf50 !important;
}

.copy-button.copied:hover {
  background-color: rgba(76, 175, 80, 0.04) !important;
}
